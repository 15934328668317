
import {
    defineComponent, reactive, ref, toRef,
    Ref
} from 'vue';
import { user } from '@/methods/rule';
import HttpRequest from '@/util/axios.config';
import goBackLogin from '@/router/back-login';

export default defineComponent({
    setup(props, { emit }) {
        const step = ref(0);

        const formOriginalRef: Ref<any> = ref(null);
        const formOriginal = reactive({
            Password: ''
        });
        const rulesOriginal = {
            Password: [
                { required: true, message: WordList.RulePasswordEmpty, trigger: 'blur' }
            ]
        };
        const checkOriginalPassword = () => {
            formOriginalRef.value.validate((valid: boolean) => {
                if (valid) {
                    HttpRequest.post('v3/web/common/manage/checkPwd', {
                        PassWd: formOriginal.Password
                    }, [() => {
                        step.value = 1;
                    }, false]);
                }
            });
        };

        const formRef: Ref<any> = ref(null);
        const formData = reactive({
            Password: '',
            Confirm: ''
        });
        const rules = {
            Password: [
                { required: true, message: WordList.RulePasswordEmpty, trigger: 'blur' },
                { validator: user.checkPassword, trigger: 'blur' }
            ],
            Confirm: [
                { required: true, message: WordList.RulePasswordConfirmEmpty, trigger: 'blur' },
                { validator: user.checkConfirmPassword(toRef(formData, 'Password')), trigger: 'blur' }
            ]
        };
        const changePassword = () => {
            formRef.value.validate((valid: boolean) => {
                if (valid) {
                    HttpRequest.post('v3/web/common/manage/editPwd', {
                        PassWd: formData.Password,
                        OldPassWd: formOriginal.Password
                    }, () => {
                        step.value = 2;
                        emit('close');
                        goBackLogin();
                    });
                }
            });
        };

        return {
            formOriginal,
            rulesOriginal,
            formOriginalRef,
            checkOriginalPassword,
            formData,
            rules,
            formRef,
            changePassword,
            step
        };
    }
});
